import React from "react";

interface ISVGProps {
  width?: string
  height?:string
  className?: string
};

const MapsSVG = (props: ISVGProps) => 
  <svg
    className= { props.className || "maps-svg" } 
    width= { props.width || "100%" }
    height= { props.height || "100%" }
    viewBox= "0 0 476.620 512"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <path
        d="M 313.9,376.7 C 355.2,308.2 407.4,211.9 407.4,162.4 407.4,78.9 339.5,11 256,11 172.5,11 104.6,78.9 104.6,162.4 c 0,49.5 52.2,145.8 93.5,214.3 -49,3.7 -160.5,17 -160.5,61.1 0,43.4 113.2,63.2 218.5,63.2 105.2,0 218.5,-19.8 218.5,-63.2 -0.1,-44.1 -111.7,-57.4 -160.7,-61.1 z M 125.5,162.4 c 0,-72 58.6,-130.6 130.5,-130.6 72,0 130.6,58.6 130.6,130.6 0,62.9 -100.2,220.7 -130.6,267.1 C 225.6,383.1 125.5,225.3 125.5,162.4 Z M 256,480.1 c -127.9,0 -197.6,-28 -197.6,-42.4 0,-11.1 46,-35 152,-41.1 19.9,32.1 35,54.8 37,57.5 2.7,3.6 10.6,7.9 17.3,0 2.1,-2.5 17.1,-25.4 37,-57.5 106,6.1 152,29.9 152,41.1 -0.1,14.5 -69.8,42.4 -197.7,42.4 z"
    />
    <path
        d="m 321.9,162.4 c 0,-36.4 -29.5,-65.9 -65.9,-65.9 -36.4,0 -65.9,29.5 -65.9,65.9 0,36.4 29.5,65.9 65.9,65.9 36.4,0 65.9,-29.5 65.9,-65.9 z m -111,0 c 0,-24.9 20.2,-45.1 45.1,-45.1 24.9,0 45.1,20.2 45.1,45.1 0,24.9 -20.2,45.1 -45.1,45.1 -24.9,0 -45.1,-20.2 -45.1,-45.1 z"
    />
  </svg>

export default MapsSVG;
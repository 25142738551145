import React from "react";

interface ISVGProps {
  width?: string
  height?: string
  className?: string
};

const BancoEstadoSVG = (props: ISVGProps) => {

  return (
    <svg
      className= { props.className || "banco_estado-svg" } 
      width= { props.width || "100%" }
      height= { props.height || "100%" }
      viewBox= "0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <polygon
        points="0,1083 1088,1083 1088,-5 0,-5 "
        fill="#595959"
        transform="matrix(0.47058824,0,0,0.47058803,0,2.3529402)" />
      <path
        d="M 294.75067,135.96456 V 87.018635 c -15.0431,16.942785 -53.12093,45.651525 -99.66048,52.711105 v 37.65054 l -36.66757,16.00158 v 32.47373 L 0,287.03807 V 426.81624 H 332.35843 V 119.96298 l -37.60776,15.53096 z"
        fill="#fefefe" />
      <path
        fill="#595959"
        d="m 195.09019,212.20703 -30.08621,11.29517 c -3.29067,1.41193 -6.11127,5.177 -6.11127,8.4714 v 23.53176 c -0.4701,3.76499 -2.82057,7.05941 -6.58135,8.00079 L 0,322.33527 v 35.76832 L 152.78145,298.8037 c 3.29069,-1.41192 6.11126,-5.177 6.11126,-8.47142 v -23.06114 c 0,-3.76499 2.8206,-6.58878 6.11127,-8.00079 l 30.08621,-11.29517 v -35.29756 z"/>
      <path
        fill="#595959"
        d="m 195.09019,280.44912 -30.08622,11.29515 c -3.29068,1.41192 -6.11126,5.17702 -6.11126,8.47138 v 23.5318 c -0.4701,3.76501 -2.82058,7.05937 -6.58136,8.00074 L 0,391.51869 v 35.29755 l 152.78145,-59.77049 c 3.29068,-1.41192 6.11126,-5.17695 6.11126,-8.47136 v -23.06118 c 0,-3.76502 2.82058,-6.5888 6.11126,-8.00075 l 30.08622,-11.29521 v -35.29756 z"/>
      <path
        fill="#9f9f9f"
        d="M 511.46474,269.6245 294.75011,87.018635 c 0,0 0,0 0,0 V 426.81624 H 511.9348 V 269.6245 Z" />
      <polygon
        points="0,1083 1088,1083 1088,902 0,902 "
        fill="#3b3b3b"
        transform="matrix(0.47049522,0,0,0.47063394,0,2.3034472)" />
    </svg>
  )
}

export default BancoEstadoSVG;